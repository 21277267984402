import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import AppContext from '../../AppContext';
import LanguageSelector from '../../components/Actions/LanguageSelector';
import ThemeChanger from '../../components/Actions/ThemeChanger';
import ProjectName from '../../components/Header/ProjectName';
import LogoMain from '../../components/Logo/LogoMain';
import LogoNka from '../../components/Logo/LogoNka';
import LogoSkc from '../../components/Logo/LogoSkc';
import LogoWordwall from '../../components/Logo/LogoWordwall';
import Topics from '../../components/Topic/Topics';

const Home = () => {
  const { isStarted, onStart } = useContext(AppContext);

  return (
    <div className="page home">
      <div className="top">
        <div className="aside"></div>
        <ProjectName />
        <div className="aside right">
          <LanguageSelector />
          <ThemeChanger />
        </div>
      </div>
      <div className="content">
        {isStarted ? (
          <Topics />
        ) : (
          <div className="start">
            <span tabIndex="0" role="button" onClick={onStart}>
              start
            </span>
          </div>
        )}
      </div>
      <div className="bottom">
        <LogoMain variant="light" />
        <LogoSkc variant="light" />
        <LogoNka />
        <LogoWordwall variant="light"/>
        <Link className="impressum" to="/impressum">
          <FormattedMessage id="impressum" />
        </Link>
      </div>
    </div>
  );
};

export default Home;
