import { useContext } from 'react';
import AppContext from '../../AppContext';
import { ReactComponent as Icon } from '../../assets/svg/logo/wordwall.svg';

const LogoWordwall = ({ variant }) => {
  const {
    theme: { current }
  } = useContext(AppContext);

  const logoVariant = current === 'accessibility' ? 'light' : variant;

  return (
    <a className="wordwall" href="https://wordwall3.hu/" target="_blank" rel="noreferrer">
      <Icon className={`logo wordwall ${logoVariant}`} />
    </a>
  );
};
LogoWordwall.defaultProps = {
  variant: 'main'
};

export default LogoWordwall;
