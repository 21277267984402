import { useContext } from 'react';
import AppContext from '../../AppContext';
import LogoMain from '../Logo/LogoMain';

const themeToLogoVariant = {
  main: 'primary',
  accessibility: 'light'
};

const Footer = () => {
  const {
    theme: { current }
  } = useContext(AppContext);

  return (
    <footer>
      <LogoMain variant={themeToLogoVariant[current]} />
    </footer>
  );
};

export default Footer;
