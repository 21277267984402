import { FormattedMessage } from 'react-intl';
import GoBack from '../../components/Actions/GoBack';
import LanguageSelector from '../../components/Actions/LanguageSelector';
import ThemeChanger from '../../components/Actions/ThemeChanger';
import ProjectName from '../../components/Header/ProjectName';
import LogoMain from '../../components/Logo/LogoMain';
import LogoNka from '../../components/Logo/LogoNka';
import LogoSkc from '../../components/Logo/LogoSkc';
import LogoWordwall from '../../components/Logo/LogoWordwall';
import data from './data.json';

const Impressum = () => (
  <div className="page impressum">
    <div className="top">
      <div className="aside">
        <GoBack />
      </div>
      <ProjectName />
      <div className="aside right">
        <LanguageSelector />
        <ThemeChanger />
      </div>
    </div>
    <div className="content">
      <div className="intro">
        <FormattedMessage id="impressum.intro" />
      </div>
      {Object.keys(data).map(groupName => (
        <div className="group" key={groupName}>
          <span className="group-title">
            <FormattedMessage id={`impressum.${groupName}`} />
          </span>
          {data[groupName].members.map(member => (
            <span key={member} className="member">
              {member}
            </span>
          ))}
        </div>
      ))}
      <div className="rights">
        <FormattedMessage id="impressum.rights" values={{ cr: () => <>&#169;</> }} />
      </div>
    </div>
    <div className="bottom">
      <LogoMain />
      <LogoSkc />
      <LogoNka variant="full" />
      <LogoWordwall />
    </div>
  </div>
);

export default Impressum;
