import { FormattedMessage } from 'react-intl';
import Barrel from '../Decorator/Barrel';

const ProjectTitle = () => (
  <div className="project-title">
    <h1>
      <FormattedMessage
        id="project.title"
        values={{
          segment: text => (
            <div className="segment">
              <span>{text}</span>
            </div>
          ),
          barrel: text => <Barrel variant="filled">{text}</Barrel>
        }}
      />
    </h1>
    <div className="subtitle">
      <FormattedMessage id="project.subtitle" />
    </div>
    <div className="subtitle">
      <FormattedMessage id="virtualExhibition" />
    </div>
  </div>
);

export default ProjectTitle;
