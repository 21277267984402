import { useContext } from 'react';
import { useIntl } from 'react-intl';
import AppContext from '../../AppContext';
import { Accessibility } from '../Icons';

const ThemeChanger = () => {
  const {
    theme: { current, changeTheme }
  } = useContext(AppContext);
  const { formatMessage } = useIntl();

  return (
    <Accessibility
      className="action"
      title={formatMessage({ id: `theme.${current}` })}
      onClick={() => changeTheme(current === 'main' ? 'accessibility' : 'main')}
      tabIndex="0"
      role="button"
    />
  );
};

export default ThemeChanger;
