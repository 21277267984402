import classnames from 'classnames';
import { ReactComponent as Left } from '../../assets/svg/chevron-left.svg';
import { ReactComponent as Right } from '../../assets/svg/chevron-right.svg';

const variants = {
  left: Left,
  right: Right
};

const Chevron = ({ variant, className, ...props }) => {
  const Icon = variants[variant];
  return <Icon className={classnames(`icon chevron-${variant}`, className)} {...props} />;
};

export default Chevron;
