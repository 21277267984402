import { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppContext from '../../AppContext';
import ContentDetails from '../../components/Content/ContentDetails';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import RecordGallery from '../../components/Record/RecordGallery';
import Record from '../Record/Record';

const Topic = ({
  history,
  match: {
    path,
    url,
    params: { topicNumber }
  }
}) => {
  const {
    theme: { current },
    data: { topics }
  } = useContext(AppContext);
  const { locale } = useIntl();
  const [topicContent, setContent] = useState();

  const currentTopic = useMemo(() => topics[topicNumber - 1], [topics, topicNumber]);

  useEffect(() => {
    if (!currentTopic) {
      return;
    }

    if (topicContent) {
      setContent(null);
    }

    fetch(`/exhibition/text/topics/${topicNumber}/${locale}.html`)
      .then(res => res.text())
      .then(setContent);
  }, [topics, topicNumber, locale]);

  if (!currentTopic) {
    return <Redirect to="/" />;
  }

  const showRecord = index => {
    history.push(`${url}/record/${index + 1}`);
  };

  return (
    <div className="page topic">
      <Header title={currentTopic.title} />
      <Switch>
        <Route path={`${path}/record/:recordNumber`} component={Record} />
        <Route>
          <ContentDetails media={currentTopic.media} text={topicContent} />
          <RecordGallery
            animation={currentTopic.animation}
            records={currentTopic.records}
            onSelect={showRecord}
          />
        </Route>
      </Switch>
      <Footer variant={current === 'main' ? 'primary' : 'light'} />
    </div>
  );
};

export default Topic;
