import { useContext } from 'react';
import classNames from 'classnames';
import AppContext from '../../AppContext';

const LogoNka = ({ variant }) => {
  const {
    theme: { current }
  } = useContext(AppContext);

  const src = `/nka/${current}/logo_${variant}.png`;

  return (
    <a className="nka" href="http://nka.hu/" target="_blank" rel="noreferrer">
      <img src={src} alt="www.nka.hu" className={classNames('logo', variant)} />
    </a>
  );
};

LogoNka.defaultProps = {
  variant: 'small'
};

export default LogoNka;
