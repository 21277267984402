import { useRef } from 'react';
import { useIntl } from 'react-intl';
import Carousel from 'react-multi-carousel';
import { Chevron } from '../Icons';
import Animation from '../Content/Animation';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 5,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 767, min: 464 },
    items: 5,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 463, min: 0 },
    items: 3,
    slidesToSlide: 1
  }
};

const RecordGallery = ({ animation, records, onSelect }) => {
  const ref = useRef();
  const { formatMessage } = useIntl();

  const slideLeft = () => ref.current.previous();

  const slideRight = () => ref.current.next();

  return (
    <div className="record-gallery">
      <Chevron variant="left" title={formatMessage({ id: 'nav.previous' })} onClick={slideLeft} />
      <Carousel
        ref={ref}
        draggable={false}
        responsive={responsive}
        arrows={false}
        containerClass="carousel-container"
      >
        {animation && <Animation {...animation} />}
        {records.map(({ title, media: { images: [{ url }] } }, i) => (
          <div
            key={title}
            className="record-thumbnail"
            tabIndex="0"
            role="button"
            onClick={() => onSelect(i)}
          >
            <div className="avatar" style={{ backgroundImage: `url(${url})` }}></div>
          </div>
        ))}
      </Carousel>
      <Chevron variant="right" title={formatMessage({ id: 'nav.next' })} onClick={slideRight} />
    </div>
  );
};
export default RecordGallery;
