import { useContext } from 'react';
import AppContext from '../../AppContext';

const LogoSkc = ({ variant }) => {
  const {
    theme: { current }
  } = useContext(AppContext);

  const logoVariant = current === 'accessibility' ? 'light' : variant;

  const src = `/skc/logo_${logoVariant}.png`;

  return (
    <a className="skc" href="https://skc.hu/" target="_blank" rel="noreferrer">
      <img src={src} alt="www.skc.hu" className="logo" />
    </a>
  );
};

LogoSkc.defaultProps = {
  variant: 'default'
};

export default LogoSkc;
