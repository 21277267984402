import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import AppContext from '../../AppContext';
import { Lang } from '../Icons';

const LanguageSelector = () => {
  const { locale, formatMessage } = useIntl();
  const {
    localization: { available, changeLocale }
  } = useContext(AppContext);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const autoCloser = () => {
      if (isOpen) {
        setOpen(false);
      }
    };
    document.addEventListener('click', autoCloser);

    return () => document.removeEventListener('click', autoCloser);
  }, [isOpen]);

  if (!available.length) {
    return null;
  }
  return (
    <div className={classnames('language-selector', { open: isOpen })}>
      <Lang
        variant={locale}
        className="action"
        title={formatMessage(
          { id: 'language.current' },
          { locale: formatMessage({ id: `language.${locale}` }) }
        )}
        onClick={() => setOpen(true)}
        tabIndex="0"
        role="menu"
      />
      <div className="dropdown">
        {available.map(name => (
          <Lang
            key={name}
            variant={name}
            className="action"
            title={formatMessage({ id: `language.${name}` })}
            onClick={() => changeLocale(name)}
            tabIndex="0"
            role="menuitem"
          />
        ))}
      </div>
    </div>
  );
};

export default LanguageSelector;
