import classnames from 'classnames';
import { ReactComponent as Filled } from '../../assets/svg/barrel.svg';

const Barrel = ({ className, children }) => (
  <div className={classnames('barrel', className)}>
    <Filled className="barrel-display" />
    {children}
  </div>
);

export default Barrel;
