import ProjectTitle from '../Typohraphy/ProjectTitle';

const ProjectName = () => (
  <div className="project-name">
    <div className="heading">
      <ProjectTitle lg />
    </div>
  </div>
);

export default ProjectName;
