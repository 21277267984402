import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import Topic from './containers/Topic/Topic';
import Home from './containers/Home/Home';
import AppContext from './AppContext';
import Impressum from './containers/Impressum/Impressum';
import InstantStarter from './components/InstantStarter';

const MEDIA_URL_BASE = `${process.env.PUBLIC_URL}/exhibition/media`;

const themes = {
  main: {
    '--primary': '#600005',
    '--secondary': '#0d4300',
    '--bright': '#ffffff',
    '--font-family': "'Open Sans', sans-serif",
    '--font-size': '16px',
    '--font-color-secondary': '#656464',
    '--font-color-primary': '#4d4d4d',
    '--scrollbar-color': '#e3e3e3',
    '--bg-textbox': 'rgba(255, 255, 255, 0.75)'
  },
  accessibility: {
    '--primary': '#ffffff',
    '--secondary': '#ffffff',
    '--bright': '#ffffff',
    '--font-family': "'Arial', sans-serif",
    '--font-size': '18px',
    '--font-color-secondary': '#ffffff',
    '--font-color-primary': '#ffffff',
    '--scrollbar-color': 'rgba(255, 255, 255, 0.25)',
    '--bg-textbox': 'transparent'
  }
};

const setThemeVariables = name => {
  const theme = themes[name];
  const [html] = document.getElementsByTagName('html');
  Object.keys(theme).forEach(key => {
    html.style.setProperty(key, theme[key]);
  });
  html.classList = [`mode-${name}`];
  localStorage.setItem('APP_THEME', name);
};

const loadData = (type, locales, mapper = data => data) =>
  Promise.all(
    locales.map(locale =>
      fetch(`/${type}/${locale}.json`)
        .then(response => response.json())
        .then(data => ({ [locale]: mapper(data) }))
    )
  ).then(data => data.reduce((result, variant) => ({ ...result, ...variant }), {}));

const exhibitionMapper = ({ main, topics }) => ({
  main,
  topics: topics.map((topic, tI) => {
    const topicResourcePath = `${MEDIA_URL_BASE}/topics/${tI + 1}`;

    const media = {
      ...topic.media,
      images: [
        {
          url: `${topicResourcePath}/cover.jpg`,
          caption: topic.media.image
        }
      ]
    };

    const records = topic.records.map((record, rI) => {
      const recordResourcePath = `${topicResourcePath}/records/${rI + 1}`;
      const images = record.media.images.map((ext, imageIndex) => ({
        url: `${recordResourcePath}/${imageIndex + 1}.${ext}`
      }));

      if (!images.length) {
        images.push({});
      }

      return {
        ...record,
        media: {
          ...record.media,
          images
        }
      };
    });

    const data = {
      ...topic,
      media,
      records
    };

    if (topic.media.animation) {
      data.animation = {
        link: topic.media.animation,
        imageUrl: `/exhibition/media/topics/${topic.id}/animation.png`
      };
    }

    return data;
  })
});

const App = () => {
  const [currentLocale, setLocale] = useState(localStorage.getItem('APP_LOCALE'));
  const [currentTheme, setTheme] = useState(() => {
    const themeName = localStorage.getItem('APP_THEME') || 'main';
    setThemeVariables(themeName);
    return themeName;
  });
  const [isStarted, setStarted] = useState(false);
  const [appData, setAppData] = useState();
  const [contentCache, setContentCache] = useState({});

  useEffect(() => {
    fetch(`/application.json`)
      .then(response => response.json())
      .then(data =>
        Promise.all([
          loadData('i18n', data.locales),
          loadData('exhibition', data.locales, exhibitionMapper)
        ]).then(([translations, exhibition]) => {
          if (!currentLocale) {
            setLocale(data.locales[0]);
          }
          setAppData({ ...data, translations, exhibition });
        })
      );
  }, []);

  if (!appData) {
    return null;
  }

  const changeLocale = locale => {
    localStorage.setItem('APP_LOCALE', locale);
    setLocale(locale);
    setContentCache({});
  };

  const changeTheme = theme => {
    setThemeVariables(theme);
    setTheme(theme);
  };

  const handleStart = () => {
    setStarted(true);
  };

  const addContentCache = (key, value) => {
    setContentCache(prevCache => ({ ...prevCache, [key]: { locale: currentLocale, value } }));
  };

  return (
    <IntlProvider locale={currentLocale} messages={appData.translations[currentLocale]}>
      <AppContext.Provider
        value={{
          localization: {
            available: appData.locales.filter(locale => locale !== currentLocale),
            changeLocale
          },
          data: appData.exhibition[currentLocale],
          contentCache,
          addContentCache,
          theme: {
            current: currentTheme,
            changeTheme
          },
          isStarted,
          onStart: handleStart
        }}
      >
        <Router>
          <Route path="/:any" component={InstantStarter} />
          <Switch>
            <Route path="/" exact strict component={Home} />
            <Route path="/impressum" component={Impressum} />
            <Route path="/topic/:topicNumber" component={Topic} />
            <Redirect to="/" />
          </Switch>
        </Router>
      </AppContext.Provider>
    </IntlProvider>
  );
};

export default App;
