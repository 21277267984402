import classNames from 'classnames';
import { ReactComponent as Icon } from '../../assets/svg/logo/main.svg';

const LogoMain = ({ variant }) => (
  <a className="eger" href="http://egrivar.eger.hu/" target="_blank" rel="noreferrer">
    <Icon className={classNames('logo main',  variant)} />
  </a>
);

LogoMain.defaultProps = {
  variant: 'primary'
};

export default LogoMain;
