import { useContext, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import AppContext from '../../AppContext';
import ContentDetails from '../../components/Content/ContentDetails';
import FunFact from '../../components/Record/FunFact';

const Record = ({ history, match: { url, params } }) => {
  const {
    data: { topics }
  } = useContext(AppContext);
  const topicNumber = +params.topicNumber;
  const recordNumber = +params.recordNumber;

  const urlBase = useMemo(() => `/${url.split('/').slice(1, -1).join('/')}`, [url]);

  const { records } = topics[topicNumber - 1];
  const currentRecord = records[recordNumber - 1];
  if (!currentRecord) {
    return <Redirect to={`${urlBase}/1`} />;
  }
  const previousRecord = () => {
    // The number 1 is the 0th index
    if (recordNumber > 1) {
      history.push(`${urlBase}/${recordNumber - 1}`);
    }
  };

  const nextRecord = () => {
    // The number is the current index + 1
    if (records[recordNumber]) {
      history.push(`${urlBase}/${recordNumber + 1}`);
    }
  };

  return (
    <div className="record">
      <ContentDetails
        title={currentRecord.title}
        media={currentRecord.media}
        text={currentRecord.text}
        onPrevious={previousRecord}
        onNext={nextRecord}
      />
      {currentRecord.funFact && <FunFact text={currentRecord.funFact} />}
    </div>
  );
};

export default Record;
