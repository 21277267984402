import { useIntl } from 'react-intl';
import classnames from 'classnames';
import Loader from 'react-loader-spinner';
import { Chevron } from '../Icons';
import Title from '../Typohraphy/Title';
import ImageViewer from './ImageViewer';
import References from './References';

const ContentDetails = ({ title, media: { images, ...links }, text, onPrevious, onNext }) => {
  const { formatMessage } = useIntl();

  const pageable = !!(onPrevious || onNext);

  const [{ caption }] = images;

  return (
    <div className={classnames('content-details', { pageable })}>
      <References media={links} />
      {onPrevious && (
        <div className="element-nav previous-element">
          <Chevron
            variant="left"
            title={formatMessage({ id: 'nav.previous' })}
            onClick={onPrevious}
          />
        </div>
      )}
      <div className="media">
        <ImageViewer images={images} />
      </div>
      {caption && (
        <div className="caption" title={caption}>
          <span>{caption}</span>
        </div>
      )}
      <div className="text">
        {title && <Title as="h2">{title}</Title>}
        {text || text === '' ? (
          <p dangerouslySetInnerHTML={{ __html: text }}></p>
        ) : (
          <Loader
            className="text-loader"
            type="Oval"
            color="var(--primary)"
            height={50}
            width={50}
          />
        )}
      </div>
      {onNext && (
        <div className="element-nav next-element">
          <Chevron variant="right" title={formatMessage({ id: 'nav.next' })} onClick={onNext} />
        </div>
      )}
    </div>
  );
};
export default ContentDetails;
