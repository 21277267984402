import { useIntl } from 'react-intl';
import { AnimationFrame } from '../Icons';

const Animation = ({ link, imageUrl }) => {
  const { formatMessage } = useIntl();

  return (
    <a
      className="animation"
      title={formatMessage({ id: 'reference.animation' })}
      href={link}
      rel="noreferrer noopener"
      target="_blank"
      style={{ backgroundImage: `url('${imageUrl}')` }}
    >
      <AnimationFrame />
    </a>
  );
};

export default Animation;
