import { useIntl } from 'react-intl';
import { Camera, Wordwall, Audio } from '../Icons';

const types = {
  wordwall: Wordwall,
  audio: Audio,
  archive: Camera
};

const References = ({ media }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="references">
      {Object.keys(types)
        .filter(type => media[type])
        .flatMap(type => {
          const Icon = types[type];
          return media[type].map(url => ({ type, Icon, url }));
        })
        .map(({ type, url, Icon }, i) => (
          <a
            key={`${type}-${i}`}
            className={`action ${type}`}
            title={formatMessage({ id: `reference.${type}` })}
            href={url}
            rel="noreferrer noopener"
            target="_blank"
          >
            <Icon />
          </a>
        ))}
    </div>
  );
};
export default References;
