import GoBack from '../Actions/GoBack';
import LanguageSelector from '../Actions/LanguageSelector';
import ThemeChanger from '../Actions/ThemeChanger';
import Title from '../Typohraphy/Title';

const Header = ({ title }) => (
  <header>
    <div className="aside">
      <GoBack />
    </div>
    <Title>{title}</Title>
    <div className="aside icons">
      <LanguageSelector />
      <ThemeChanger />
    </div>
  </header>
);

export default Header;
