import { memo, useContext, useEffect } from 'react';
import AppContext from '../AppContext';

const InstantStarter = () => {
  const { isStarted, onStart } = useContext(AppContext);
  useEffect(() => {
    if (!isStarted) {
      onStart();
    }
  }, []);
  return null;
};

export default memo(InstantStarter, () => true);
