import Lightbox from 'react-image-lightbox';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Loader from 'react-loader-spinner';
import classNames from 'classnames';
import Fullscreen from '../Icons/Fullscreen';

const ImageViewer = ({ images }) => {
  const { formatMessage } = useIntl();
  const [isFullscreen, changeFullscreen] = useState(false);
  const [currentPhoto, setPhoto] = useState(0);
  const [isImageReady, setImageReady] = useState(false);

  const open = () => {
    setPhoto(0);
    changeFullscreen(true);
  };

  const close = () => {
    changeFullscreen(false);
  };

  const moveTo = shift => {
    setPhoto(prevIndex => prevIndex + shift);
  };

  const [{ url, caption }] = images;

  useEffect(() => {
    setImageReady(false);
  }, [url]);

  return (
    <div className="image-viewer">
      <img
        key={url}
        className={classNames({ ready: isImageReady })}
        src={url}
        alt={caption}
        onLoad={() => setImageReady(true)}
      />
      {!isImageReady && (
        <Loader
          className="image-loader"
          type="Oval"
          color="var(--primary)"
          height={50}
          width={50}
        />
      )}
      <Fullscreen
        title={formatMessage({ id: 'fullscreen' })}
        onClick={open}
        tabIndex="0"
        role="button"
      />
      {isFullscreen && (
        <Lightbox
          mainSrc={images[currentPhoto].url}
          nextSrc={(images[currentPhoto + 1] || {}).url}
          prevSrc={(images[currentPhoto - 1] || {}).url}
          onCloseRequest={close}
          onMovePrevRequest={() => moveTo(-1)}
          onMoveNextRequest={() => moveTo(1)}
          clickOutsideToClose={false}
        />
      )}
    </div>
  );
};
export default ImageViewer;
