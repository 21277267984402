import classnames from 'classnames';
import { ReactComponent as HU } from '../../assets/svg/lang-hu.svg';
import { ReactComponent as EN } from '../../assets/svg/lang-en.svg';

const variants = {
  hu: HU,
  en: EN
};

const Lang = ({ variant, className, ...props }) => {
  const Icon = variants[variant];
  return <Icon className={classnames('icon lang', className)} {...props} />;
};

export default Lang;
