/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const FunFact = ({ text }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setOpen(false);
    }
  }, [text]);

  return (
    <div className="fun-fact">
      <div className="toggler">
        <span tabIndex="0" role="button" onClick={() => setOpen(!isOpen)}>
          <FormattedMessage id="didYouKnow" />
        </span>
      </div>
      {isOpen && (
        <div className="text">
          <p dangerouslySetInnerHTML={{ __html: text }}></p>
        </div>
      )}
    </div>
  );
};

export default FunFact;
