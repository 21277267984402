/* eslint-disable react/no-danger */
import { useContext, useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import AppContext from '../../AppContext';
import Animation from '../Content/Animation';

const CONTENT_CACHE_KEY = 'MAIN';

const Topics = () => {
  const {
    data: { topics },
    contentCache,
    addContentCache
  } = useContext(AppContext);
  const { locale } = useIntl();

  const [mainContent, setContent] = useState(contentCache[CONTENT_CACHE_KEY]);

  const animations = useMemo(
    () => topics.filter(topic => topic.animation).map(topic => topic.animation),
    [topics]
  );
  
  useEffect(() => {
    if (mainContent?.locale === locale) {
      return;
    }

    if (mainContent) {
      setContent(null);
    }

    fetch(`/exhibition/text/main/${locale}.html`)
      .then(res => res.text())
      .then(value => {
        setContent({ locale, value });
        addContentCache(CONTENT_CACHE_KEY, value);
      });
  }, [locale]);

  return (
    <div className="topics">
      {mainContent ? (
        <p dangerouslySetInnerHTML={{ __html: mainContent.value }}></p>
      ) : (
        <Loader
          className="main-content-loader"
          type="Oval"
          color="var(--primary)"
          height={50}
          width={50}
        />
      )}
      {topics.map(topic => (
        <Link key={topic.title} className="nav-topic" to={`/topic/${topic.id}`}>
          {topic.title}
        </Link>
      ))}
      <div className="animations">
        {animations.map(({ link, imageUrl }) => (
          <Animation key={link} link={link} imageUrl={imageUrl} />
        ))}
      </div>
    </div>
  );
};
export default Topics;
