import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Back } from '../Icons';

const GoBack = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const toPreviousGroup = () => {
    const paths = history.location.pathname.split('/');

    const previousGroupUrl = paths.slice(0, paths.length - 2).join('/') || '/';
    history.push(previousGroupUrl);
  };

  return (
    <Back
      className="action"
      title={formatMessage({ id: 'nav.back' })}
      onClick={toPreviousGroup}
      tabIndex="0"
      role="button"
    />
  );
};

export default GoBack;
